import React, { useState, useEffect, ChangeEvent } from 'react';
import { Row, Col } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { DetailItem } from '../../../../features/details';
import { updateTerminalMdbSetting } from '../../../../services/api/terminalMdbSettings'
import { useKeycloak } from '@react-keycloak/web'
import { PropertyInfo } from '../../../../services/openapi/models/PropertyInfo';
import { Link } from "react-router-dom";
import TerminalMdbSettingDto from './TerminalMdbSettingDto';

const TerminalMdbSettingDetails = () => {
    const [tms, setTms] = useState<any>();
    const [showInputEle, setShowInputEle] = useState<boolean>(false);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const setData = (data: TerminalMdbSettingDto) => {
        localStorage.setItem('TerminalMdbSetting', JSON.stringify(data));
    }

    useEffect(() => {
        if (tms == null) {
            const json = localStorage.getItem('TerminalMdbSetting') ?? "";

            if (json !== "") {
                const item = JSON.parse(json);
                setTms(item);
                console.log(item);
            }
        }
    }, []);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        if (e.currentTarget.checkValidity()) {
            {
                let t: TerminalMdbSettingDto = tms;
                updateTerminalMdbSetting(token, t.terminalId ?? 0, t);
                setData(t);
                setShowInputEle(!showInputEle);
            }
        }
    }

    return (
        <>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <form onSubmit={e => handleSubmit(e)}>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" className="">
                                        <h4 className="mb-2 qvend-title">Terminal MDB</h4>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" xs="12" className="mt-2">

                                        <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                            <div>
                                                {tms && Object.entries<PropertyInfo>(tms).filter(([key, value]) => tms["propertyNames"][key] !== undefined && (tms["propertyNames"][key].editType !== '' || tms["propertyNames"][key].editType === '' && value !== null)).sort((a, b) => tms["propertyNames"][a[0]].sortOrderLevel - tms["propertyNames"][b[0]].sortOrderLevel).map(([key, value], index) => (
                                                    <DetailItem key={index} propertyName={tms["propertyNames"][key].name} defaultValue={value ?? ""} rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"} editType={tms["propertyNames"][key].editType} pattern={tms["propertyNames"][key].valPattern}
                                                        handleDoubleClick={() => setShowInputEle(true)}
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => { tms[key] = (e.target.value); setTms(tms); }} showInputEle={showInputEle}></DetailItem>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "12px" }}>
                                    <Col>
                                        <Link to="/dashboard/details/terminal" onClick={() => { }}>
                                            <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { }}>Annuleren</button>
                                        </Link>
                                    </Col>
                                    <Col className="text-end">
                                        <button type="submit" className="btn btn-fixed-width" style={{ fontSize: '11px' }}>{showInputEle ? "Opslaan" : "Wijzigen"}</button>
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default TerminalMdbSettingDetails;