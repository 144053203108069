import React, { useState, useEffect, ChangeEvent, MouseEventHandler } from 'react';
import { Row, Col } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { DetailItem } from '../../../../features/details';
import { Transaction } from '../../../../services/openapi';
import { useKeycloak } from '@react-keycloak/web'
import { PropertyInfo } from '../../../../services/openapi/models/PropertyInfo';
import { Link, useNavigate } from "react-router-dom";
import { NotImplementedWarningModal } from '../../../../components/NotImplementedWarningModal';

const TransactionDetails = () => {
    const [transaction, setTransaction] = useState<any>();
    const [showInputEle, setShowInputEle] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const setTransactionData = (data: Transaction) => {
        localStorage.setItem('Transaction', JSON.stringify(data));
    }

    useEffect(() => {
        if (transaction == null) {
            const json = localStorage.getItem('Transaction') ?? "";
            const item = JSON.parse(json);

            setTransaction(item);
        }
    }, []);

    return (
        <>
            <div className="">
                <NotImplementedWarningModal
                    showModal={showModal}
                    handleClose={() => {
                        setShowModal(false);
                    }}
                />
            </div>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" className="">
                                        <h4 className="mb-2 qvend-title">Betalingdetails</h4>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" xs="12" className="mt-2">
                                        <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                            <div>
                                            {transaction && Object.entries<any>(transaction).filter(([key, value]) => transaction["propertyNames"][key] !== undefined && (transaction["propertyNames"][key].editType !== '' || transaction["propertyNames"][key].editType === '' && value !== null)).sort((a, b) => transaction["propertyNames"][a[0]].sortOrderLevel - transaction["propertyNames"][b[0]].sortOrderLevel).map(([key, value], index) => (
                                                    <DetailItem key={index} propertyName={transaction["propertyNames"][key].name} defaultValue={typeof value == "boolean" ? value ? "Ja" : "Nee" : value ?? ""} rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"} editType={transaction["propertyNames"][key].editType} pattern={transaction["propertyNames"][key].valPattern}
                                                        handleDoubleClick={() => setShowInputEle(true)}
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => { transaction[key] = (e.target.value); setTransaction(transaction); }} showInputEle={showInputEle}></DetailItem>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>                                                                
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <Link to="/dashboard/lists/transactions" onClick={() => { }}>
                                            <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { }}>Terug</button>
                                        </Link>
                                    </Col>
                                    <Col className="text-end">                                        
                                    </Col>
                                </Row>
                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default TransactionDetails;